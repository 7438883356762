export const ABOUT_PAGE="/about/"
export const SERVICES_PAGE="/services/"
export const CONTACT_PAGE="/contact/"
export const METRIC_DESIGN_GUIDELINES="/metric-design-guidelines/"
export const WORKSHOPS_EQUITY_DATAVIZ="/workshops/equity-dataviz/"
export const WORKSHOPS_PEOPLE_DATAVIZ="/workshops/visualizing-people/"
export const WORKSHOPS_EQUITABLE_EPIDEMIOLOGY="/workshops/equitable-epidemiology-population-health-dataviz-training/"
export const WORKSHOPS="/workshops/"
export const CROWD_PAGE="/crowd/"
export const HOW_TO="/how-to/"
export const CDN="https://3iap.com/cdn/"