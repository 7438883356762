import {isEmpty} from 'lodash'

export const truncatePleasantly = (s, maxLength, ellipses=null) => {
    if (isEmpty(s)) return s

    // Remove trailing commas
    let output =  s.replace(new RegExp(`^(.{${maxLength}}[a-zA-Z0-9]*).*`, "sm"), "$1")
        .split("").reverse().join("")
        .replace(/^[^a-z\.!?]*/, "")
        .split("").reverse().join("")

    if (maxLength < s.length && ellipses ) {
        output = output + ellipses
    }
    return output
}
export const isNonEmpty = (s) => {
  return s && s.length && 0 < s.length
}
export const isValidEmailAddress = (s) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(s)
}
export const isValidNameLength = (s) => {
  return /^.{0,128}$/i.test(s)
}
export const isValidMessageLength = (s) => {
  return /^.{0,1024}$/i.test(s)
}

export const joinPaths = (parts, sep="/") => {
    parts = parts.map((s,i)=>{
        let output = s
        if (s.startsWith(sep) && (i !==0)) {
            s = s.slice(1)
        }
        if (s.endsWith(sep) && (i !== parts.length -1)) {
            s = s.slice(0, s.length-1)
        }
        return s
    })
    return parts.join(sep)
}

// console.log(joinPaths(["/hello", "/world/"]))