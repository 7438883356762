/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import {useStaticQuery, graphql} from "gatsby"
import { useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import {joinPaths} from '../utils/index'
import * as PATHS from '../utils/paths'

const SEO = ({description, lang, meta, title, location, imagePath}) => {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
            social {
              twitter
            }
          }
        }
      }
    `
    )

    const metaDescription = description || site.siteMetadata.description
    let canonicalUrl = site.siteMetadata.siteUrl
    if (location && location.pathname) {
        canonicalUrl = canonicalUrl + location.pathname;
    }
    let imageUrl
    if (imagePath) {
        imageUrl = site.siteMetadata.siteUrl + imagePath
    } else {
        imageUrl = joinPaths([PATHS.CDN, "/images/3iap-og-image.png"])
    }

    useEffect(() => {
        amplitude.init("9ed9f8a36d36019c0b73f78536a58704");
    }, []);

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    property: `og:type`,
                    content: `website`,
                }

            ].concat(meta)}
        >
            <link rel="canonical" href={canonicalUrl}/>
            <meta name="description" content={metaDescription}/>

            {imageUrl &&
            (<meta property="og:image" content={imageUrl}/>)}
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:title" content={title}/>
            <meta property="og:type" content="website"/>
            <meta property="og:description" content={metaDescription}/>

            {imageUrl &&
            (<meta name="twitter:image" content={imageUrl}/>)}
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:creator" content={site.siteMetadata.social.twitter}/>
            <meta name="twitter:description" content={metaDescription}/>
            <meta name="twitter:card" content="summary_large_image"/>


        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
